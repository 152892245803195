import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import enums from "helpers/enums";
import DetailHeader from "./components/DetailHeader";
import { isVendor, userDetails } from "@ui/Utils/helper";
import { getByCode, updateRecordWithHook } from "../../api";

// const validationSchema = yup.object().shape({
//   product: yup.string().required("Required"),
//   item: yup
//     .object()
//     .shape({
//       name: yup.string().required("Required"),
//     })
//     .required("Required"),
//   size: yup.string().required("Required"),
//   color: yup.string().required("Required"),
//   stick: yup.string().when("item", {
//     is: (data) => data === "rings",
//     then: yup.string().required("Required").nullable(),
//     otherwise: yup.string().nullable(),
//   }),
// });

const entitySchema = {};

const StockOrderDetail = () => {
  const formMethods = useForm({
    defaultValues: entitySchema,
    mode: "all",
    // resolver: yupResolver(validationSch),
  });
  const navigate = useNavigate();
  const model = enums.models["e-creatives"]?.stockOrders;
  const params = useParams();
  const [isEditable, setIsEditable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initialValues, setInitialValues] = useState({});
  // const values = formMethods.watch();

  useEffect(() => {
    (async () => {
      const id = params?.code;

      try {
        if (id !== "create") {
          setLoading(true);
          const res = await getByCode(model, id);
          formMethods.reset(res);
          setInitialValues(res);
          setIsEditable(false);
        } else {
          setIsEditable(true);
        }
      } catch (err) {
        navigate("/" + enums.models["e-creatives"].stockOrders);
        console.log(err);
      }
      setLoading(false);
    })();
  }, [params?.code]);

  const onSubmit = async (values) => {
    try {
      let res = {},
        action = "Created";

      if (values._id) {
        res = await updateRecordWithHook(
          enums.models["e-creatives"]?.stockOrders,
          values
        );
        action = "Updated";
      }

      CustomEventEmitter.emit("alert_success", action + " Successfully");

      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <FormProvider {...formMethods}>
      <DetailHeader
        isEditable={isEditable}
        onSubmit={onSubmit}
        loading={loading}
        setIsEditable={setIsEditable}
        initialValues={initialValues}
      />
    </FormProvider>
  );
};

export const defaultFilter = (item = {}, subCatGroup = {}) => {
  if (isVendor) {
    return (userDetails.vendorCode || []).find(
      (vCode) =>
        (item?.orderStatus !== enums["vendor-portal"].orderStatus.pending &&
          vCode === item?.product?.vendorCode) ||
        (subCatGroup?.vendors || []).find(
          (vendor) =>
            vendor.vendorCode === vCode &&
            vendor.orderStatus !== enums["vendor-portal"].orderStatus.pending
        )
    );
    // &&
    // (isDivisionControlled
    //   ? item.product?.division?._id === selectedDivisionId
    //   : true)
  } else {
    return true;
    // isDivisionControlled
    //   ? item.product?.division?._id === selectedDivisionId
    // : ;
  }
};

export default StockOrderDetail;
