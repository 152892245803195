import * as React from "react";
import { FormProvider, useForm } from "react-hook-form";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button, Grid, Tooltip } from "@material-ui/core";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {
  formatDateToRead,
  imageExpired,
  isAdmin,
  isSuperAdmin,
  isVendor,
  oopsNotFound,
  regexPurify,
} from "@ui/Utils/helper";
import enums from "helpers/enums";
import {
  getSingleRecord,
  updateRecord,
} from "@ui/ComponentUtils/blueprintAPIs";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import { CustomCopyIcon, getOverDueColor } from "./List";
import { displayMeltingAsPerClient } from "../Detail/components/ProductsView";
import RHTextField from "@ui/ReactHookFormControls/RHTextField";
import RHAutoComplete from "@ui/ReactHookFormControls/RHAutoComplete";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import {
  createRecordWithHook,
  isVendorCodeEditable,
  updateRecordWithHook,
} from "../api";
import HoverIcons from "@ui/assets/commonIcons/customIcons/HoverIcons";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SaveIcon from "@mui/icons-material/Save";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import { useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { MyCarousel } from "@ui/MuiComponents/index";
import RHDatePicker from "@ui/ReactHookFormControls/RHDatePicker";
import ScrollTopButton from "@ui/Actions/Scroll/ScrollTopButton";
import { TextField } from "@material-ui/core/index";
import { orderedStatusArray } from "../Detail/Detail";
import CameraIcon from "@mui/icons-material/Camera";
import CameraOutlinedIcon from "@mui/icons-material/CameraOutlined";
import ProductDownload from "../ProductDownload/ProductDownload";

const CollapseList = ({
  data = [],
  setData = () => {},
  constData = [],
  columns,
  title,
  getData,
  setTotalCalc,
  clientSetting,
  currentEditRow,
  setCurrentEditRow,
}) => {
  const windowExpandAll = window.localStorage.getItem("expandAll")
    ? JSON.parse(window.localStorage.getItem("expandAll"))
    : false;
  const [expandAll, setExpandAll] = React.useState(
    windowExpandAll ? true : false
  );
  const [vendorCodes, setVendorCodes] = React.useState([]);
  const [searchText, setSearchText] = React.useState("");

  React.useEffect(() => {
    (async () => {
      try {
        setVendorCodes(
          (
            (await getSingleRecord(
              enums.models["vendor-portal"].vendorCodes
            )) || {}
          )?.availableCodes || []
        );
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const checkMatch = (source) => {
    return regexPurify(source?.toString())
      .toLowerCase()
      .match(regexPurify(searchText?.toString()).toLowerCase());
  };

  React.useEffect(() => {
    let modifiedData = [];

    if (searchText?.trim()) {
      const mainData = [...constData];
      modifiedData = mainData.map((cd, cdIndex) => {
        if (
          checkMatch(cdIndex + 1) ||
          checkMatch(cd.quotationNo) ||
          checkMatch(cd.vendorCode) ||
          checkMatch(cd.styleNo) ||
          checkMatch(cd.customer?.name) ||
          checkMatch(cd.customer?.address?.line1) ||
          (cd.products || []).find(
            (prod) =>
              checkMatch(prod.styleNo) ||
              checkMatch(prod.skuNumber) ||
              checkMatch(prod.orderStatus) ||
              checkMatch(prod.adminRemarks) ||
              checkMatch(prod.exhibitionRemarks) ||
              checkMatch(prod.id)
          )
        ) {
          return cd;
        }

        return null;
      });
    } else {
      modifiedData = constData;
    }

    let totalGrossWeight = 0,
      totalNetWeight = 0;

    modifiedData.map((quot) => {
      (quot?.products || []).map((prod) => {
        totalGrossWeight += prod.grossWeight || 0;
        totalNetWeight += prod.netWeight || 0;
      });
    });

    setData(modifiedData);

    setTotalCalc({
      grossWeight: totalGrossWeight,
      netWeight: totalNetWeight,
    });
  }, [searchText]);

  return (
    <div>
      <Grid container spacing={1} style={{ marginBottom: "1px" }}>
        <Grid
          item
          xs={4}
          container
          // justify="center"
          style={{ fontWeight: "bold", paddingTop: "16px" }}
        >
          {title}
        </Grid>
        <Grid item xs={8}>
          {currentEditRow === "none" ? (
            <TextField
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              label={"Search"}
              style={{ width: "100%" }}
              variant="outlined"
            />
          ) : (
            ""
          )}
        </Grid>
      </Grid>

      <ScrollTopButton />

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell
                onClick={() => {
                  if (isNaN(currentEditRow)) {
                    setExpandAll(!expandAll);
                    window.localStorage.setItem("expandAll", !expandAll);
                  }
                }}
                style={{
                  cursor: isNaN(currentEditRow) && "pointer",
                  backgroundColor: "#00CED1",
                }}
              >
                {!isNaN(currentEditRow) ? null : !expandAll ? (
                  <Tooltip title="Expand All">
                    <ExpandCircleDownIcon />
                  </Tooltip>
                ) : (
                  <Tooltip title="Collapse All">
                    <ExpandLessIcon
                      style={{
                        backgroundColor: "black",
                        color: "white",
                        borderRadius: "50%",
                      }}
                    />
                  </Tooltip>
                )}
              </TableCell>
              {columns.map((col, colIndex) => (
                <TableCell
                  style={{ whiteSpace: "pre-line", backgroundColor: "#00CED1" }}
                  key={colIndex}
                >
                  {col.title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {(data || []).map(
              (row, rowIndex) =>
                row &&
                (!isNaN(currentEditRow)
                  ? currentEditRow === rowIndex
                  : true) && (
                  <Row
                    key={rowIndex}
                    row={row}
                    rowIndex={rowIndex}
                    columns={columns}
                    data={data}
                    getData={getData}
                    setTotalCalc={setTotalCalc}
                    clientSetting={clientSetting}
                    expandAll={expandAll}
                    vendorCodes={vendorCodes}
                    setCurrentEditRow={setCurrentEditRow}
                  />
                )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

function Row({
  row,
  rowIndex,
  columns,
  data,
  getData,
  setTotalCalc,
  clientSetting,
  expandAll,
  vendorCodes,
  setCurrentEditRow,
}) {
  const [open, setOpen] = React.useState(false);
  const [isEditable, setIsEditable] = React.useState(false);
  const formMethods = useForm({
    defaultValues: row,
    mode: "all",
  });
  const { setValue, getValues } = formMethods;
  const navigate = useNavigate();
  const [openPreview, setOpenPreview] = React.useState({});
  const [openDownloadPopup, setOpenDownloadPopup] = React.useState({});

  React.useEffect(() => {
    setOpen(expandAll);
  }, [expandAll]);

  const navigateToDetails = () => {
    if (!isEditable)
      navigate(`/${enums.models["vendor-portal"]?.quotations}/${row.code}`);
  };

  const onSubmit = async (values) => {
    try {
      const model = enums.models["vendor-portal"].quotations;

      if (!values._id) {
        await createRecordWithHook(model, values);
      } else {
        await updateRecordWithHook(model, values);
      }

      CustomEventEmitter.emit("alert_success", "Updated Successfully");
      window.location.reload();
    } catch (err) {
      console.log(err);
    }
  };

  const handleReceived = async (row, receivedBoolean) => {
    try {
      let message = "";
      if (
        row?.products?.find(
          (prod) =>
            prod?.orderStatus === enums["vendor-portal"]?.orderStatus?.pending
        )
      ) {
        message = "Some Products are still not assigned. "; // empty space at last is required
      }
      if (
        window.confirm(
          message +
            `Are you sure to mark ${row?.quotationNo} from ${
              row?.vendorCode
            } as ${receivedBoolean ? "Received" : "NOT Received"}?`
        )
      ) {
        row.received = receivedBoolean;

        if (receivedBoolean)
          row.products = (row?.products || []).map((prod) => ({
            ...prod,
            orderStatus: enums["vendor-portal"]?.orderStatus?.delivered,
          }));

        const res = await updateRecord(
          enums.models["vendor-portal"]?.quotations,
          row
        );

        let totalGrossWeight = 0,
          totalNetWeight = 0;

        data.map((quot) => {
          (quot?.products || []).map((prod) => {
            totalGrossWeight += prod.grossWeight || 0;
            totalNetWeight += prod.netWeight || 0;
          });

          if (
            quot?.quotationNo === row.quotationNo &&
            row?.vendorCode === quot.vendorCode
          ) {
            return res;
          }

          return quot;
        });

        setTotalCalc({
          grossWeight: totalGrossWeight,
          netWeight: totalNetWeight,
        });

        getData(clientSetting);

        CustomEventEmitter.emit("alert_success", "Updated Successfully");
      }
    } catch (err) {
      console.log(err);
      CustomEventEmitter.emit("alert_error", "Something went wrong!");
    }
  };

  const handleFinally = () => {
    // After Cancel or Save on Edit
    setIsEditable(false);
    setCurrentEditRow("none");
  };

  return (
    <FormProvider {...formMethods}>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <div>
            {isEditable ? (
              <>
                <HoverIcons
                  tooltip="Cancel"
                  muiIcon={<CancelOutlinedIcon />}
                  muiIconHover={<CancelIcon />}
                  onClick={() => {
                    if (window.confirm("Are you sure to Discard / Cancel?")) {
                      handleFinally();
                    }
                  }}
                />

                <HoverIcons
                  tooltip="Save"
                  muiIcon={<SaveOutlinedIcon />}
                  muiIconHover={<SaveIcon />}
                  onClick={() => {
                    formMethods.handleSubmit(onSubmit)();
                  }}
                />
              </>
            ) : (
              <>
                <HoverIcons
                  tooltip="View"
                  onClick={() => {
                    navigateToDetails();
                  }}
                  muiIcon={<RemoveRedEyeOutlinedIcon />}
                  muiIconHover={<RemoveRedEyeIcon />}
                />

                <HoverIcons
                  tooltip="Edit"
                  onClick={() => {
                    setCurrentEditRow(rowIndex);
                    setIsEditable(true);
                    setOpen(true);
                  }}
                  muiIcon={<ModeEditOutlineOutlinedIcon />}
                  muiIconHover={<ModeEditIcon />}
                />
              </>
            )}
          </div>

          <IconButton aria-label="expand row" size="small">
            {isEditable ? null : open ? (
              <KeyboardArrowUpIcon onClick={() => setOpen(!open)} />
            ) : (
              <KeyboardArrowDownIcon onClick={() => setOpen(!open)} />
            )}
          </IconButton>
        </TableCell>

        <TableCell scope="row">
          {isEditable ? null : row?.received && (isAdmin || isSuperAdmin) ? (
            <Tooltip title="Mark as NOT Received">
              <IconButton
                color="inherit"
                sx={{}}
                onClick={() => handleReceived(row, false)}
                edge="start"
              >
                <CheckBoxOutlinedIcon style={{ color: "green" }} />
              </IconButton>
            </Tooltip>
          ) : (
            (isAdmin || isSuperAdmin) && (
              <Tooltip title="Mark as Received">
                <IconButton
                  color="inherit"
                  sx={
                    {
                      // mr: 2,
                      // display: { sm: "none" }
                    }
                  }
                  onClick={() => handleReceived(row, true)}
                  edge="start"
                >
                  <CheckBoxOutlineBlankIcon />
                </IconButton>
              </Tooltip>
            )
          )}
        </TableCell>

        <TableCell>
          {clientSetting?.quotationView?.priorityView
            ? row.priority
            : rowIndex + 1}
        </TableCell>

        {!isVendor && (
          <>
            <TableCell>
              <div>{row?.customer?.name}</div>
              <CustomCopyIcon text={row?.customer?.name} />
            </TableCell>
            <TableCell>
              <div>{row?.customer?.address?.line1}</div>
              <CustomCopyIcon text={row?.customer?.address?.line1} />
            </TableCell>
          </>
        )}
        <TableCell>
          {row.quotationNo}
          <CustomCopyIcon text={row.quotationNo} />
        </TableCell>
        <TableCell>
          {/* {isEditable && !isVendor && isVendorCodeEditable({}, row) ? (
            <RHAutoComplete
              disableClearable
              options={vendorCodes}
              size="small"
              onChange={(_, value) => {
                const tempValues = getValues();

                const tempProducts = (tempValues.products || []).map((prod) => {
                  if (
                    !(
                      prod.orderStatus !== enums["vendor-portal"]?.orderStatus.pending ||
                      prod.adminRemarks
                    )
                  ) {
                    return { ...prod, vendorCode: value };
                  }

                  return prod;
                });

                setValue("products", tempProducts);
              }}
              name={`vendorCode`}
            />
          ) : (
            
          )} */}
          <p
            style={{
              fontWeight: "bolder",
              fontSize: "16px",
              paddingTop: "10px",
              color: "black",
            }}
          >
            {row.vendorCode}
          </p>
        </TableCell>
        <TableCell>
          {(() => {
            let sum = 0;
            row?.products &&
              row.products.map((prod) => (sum += Number(prod?.grossWeight)));
            sum = sum?.toFixed(3);

            let netSum = 0;
            row?.products &&
              row.products.map((prod) => (netSum += Number(prod?.netWeight)));
            netSum = netSum?.toFixed(3);

            return (
              <>
                <div>{`${sum}`}</div>
                <div style={{ marginTop: "8px" }}>{`${netSum}`}</div>
              </>
            );
          })()}
        </TableCell>

        <TableCell>
          <div>{row?.remarks}</div>
          <CustomCopyIcon text={row?.remarks} />
        </TableCell>

        <TableCell>
          {row?.orderDate && formatDateToRead(row?.orderDate)}
        </TableCell>

        <TableCell>
          {(() => {
            const dueDates = (row?.products || [])
              .filter((prod) => !!prod?.dueDate)
              .map((prod) => new Date(prod.dueDate).getTime());

            const minDueDate = dueDates.length
              ? new Date(Math.min(...dueDates))
              : null;

            const text = minDueDate ? formatDateToRead(minDueDate) : "";

            return (
              <div
                style={{
                  color: getOverDueColor(minDueDate),
                  fontWeight: "bold",
                }}
              >
                {text || ""}
              </div>
            );
          })()}
        </TableCell>

        <TableCell>
          {(() => {
            const products = row?.products || [];

            let color = "#ff6e19", // dark orange
              currentStatusIndex = -1;

            // Finding lowest order Status to be Shown in List Page based on its array of Products having different Order Status
            products.map((seenProd) => {
              orderedStatusArray.map((stat, statIndex) => {
                if (stat === seenProd.orderStatus) {
                  if (
                    currentStatusIndex === -1 ||
                    statIndex < currentStatusIndex
                  ) {
                    // To set currentStatusIndex to lowest status index that is present in products array
                    if (statIndex === orderedStatusArray.length - 1) {
                      currentStatusIndex = 0;
                    } else {
                      currentStatusIndex = statIndex;
                    }
                  }
                }
              });
            });
            const modifiedDates = (row?.products || [])
              .filter((prod) => prod.dateModified)
              .map((prod) => new Date(prod.dateModified).getTime());

            let statusDate = modifiedDates?.length
              ? Math.min(...modifiedDates)
              : null;
            statusDate = statusDate ? formatDateToRead(statusDate) : "";

            const status = orderedStatusArray[currentStatusIndex];

            switch (status) {
              case enums["vendor-portal"]?.orderStatus?.pending:
                color = "#ff2919"; // dark red
                break;
              case enums["vendor-portal"]?.orderStatus?.canceled:
                color = "#ff2919"; // dark red
                break;

              case enums["vendor-portal"]?.orderStatus?.assigned:
                color = "#ff6e19"; // dark orange
                break;
              case enums["vendor-portal"]?.orderStatus?.inProgress:
                color = "#ff6e19"; // dark orange
                break;

              case enums["vendor-portal"]?.orderStatus?.delivered:
                color = "#008a0e"; // dark green
                break;
            }

            return (
              <div>
                <p style={{ color: color }}>
                  {isVendor &&
                  status === enums["vendor-portal"]?.orderStatus?.assigned
                    ? enums["vendor-portal"]?.orderStatus?.pending
                    : status}
                </p>
                <p style={{ color: color }}>{statusDate}</p>
              </div>
            );
          })()}
        </TableCell>
      </TableRow>

      {/* ************************************************************************************************************ */}
      {/* QUOTATION PRODUCTS */}
      <TableRow>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={columns.length + 1}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Products
              </Typography>
              <Table
                //   size="small"
                aria-label="purchases"
              >
                <TableHead>
                  <TableRow>
                    <TableCell style={styles.productsColumns}>ID</TableCell>
                    <TableCell style={styles.productsColumns}>
                      Cust. OD#
                    </TableCell>

                    <TableCell style={styles.productsColumns}>Design</TableCell>
                    <TableCell style={styles.productsColumns}>Qty</TableCell>
                    <TableCell
                      style={{
                        ...styles.productsColumns,
                        whiteSpace: "pre-line",
                      }}
                    >
                      {"Gross\nNet"}
                    </TableCell>
                    <TableCell
                      style={{
                        ...styles.productsColumns,
                        whiteSpace: "pre-line",
                      }}
                    >
                      {"Color\nMelting"}
                    </TableCell>
                    <TableCell style={styles.productsColumns}>Size</TableCell>

                    <TableCell style={styles.productsColumns}>
                      Exhibition Remarks
                    </TableCell>
                    <TableCell style={styles.productsColumns}>
                      Admin Remarks
                    </TableCell>
                    <TableCell style={styles.productsColumns}>
                      Vendor Code
                    </TableCell>
                    <TableCell style={styles.productsColumns}>
                      Due Date
                    </TableCell>
                    <TableCell style={styles.productsColumns}>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(row.products || []).map((prod, prodIndex) => (
                    <TableRow key={prodIndex} style={{ width: "100%" }}>
                      <TableCell style={styles.productsBody}>
                        <b>{prod.id}</b>

                        <div style={{ paddingTop: "8px" }}>
                          <img
                            style={{
                              height: "90%",
                              width: 88,
                              borderRadius: 10,
                              backgroundColor: "#fff",
                              cursor: "pointer",
                            }}
                            src={
                              prod.images?.[0]?.signedUrl ||
                              prod.images?.[0]?.location
                            }
                            alt="image not found"
                            onError={(event) => {
                              event.target.src = imageExpired;
                            }}
                            onClick={() =>
                              setOpenPreview({ product: prod, prodIndex })
                            }
                          />
                          {openPreview?.prodIndex === prodIndex &&
                            openPreview?.product?.images?.[0]?.signedUrl && (
                              <MyCarousel
                                openPreview={
                                  !!openPreview?.product?.images?.[0]?.signedUrl
                                }
                                setOpenPreview={() => setOpenPreview({})}
                                items={openPreview?.product?.images}
                              />
                            )}
                        </div>

                        <Grid
                          container
                          spacing={2}
                          style={{ paddingTop: "16px" }}
                        >
                          <Grid item xs={6}>
                            {prod.id && <CustomCopyIcon text={prod.id} />}
                          </Grid>
                          <Grid item xs={6}>
                            {!isEditable && (
                              <HoverIcons
                                onClick={() =>
                                  setOpenDownloadPopup({ [prodIndex]: true })
                                }
                                tooltip={"Snapshot"}
                                muiIcon={<CameraOutlinedIcon />}
                                muiIconHover={<CameraIcon />}
                              />
                            )}
                          </Grid>
                        </Grid>

                        {openDownloadPopup && (
                          <ProductDownload
                            openDownloadPopup={openDownloadPopup}
                            setOpenDownloadPopup={setOpenDownloadPopup}
                            product={prod}
                            productIndex={prodIndex}
                            quotation={row}
                          />
                        )}
                      </TableCell>

                      <TableCell style={styles.productsBody}>
                        {isEditable && !isVendor ? (
                          <RHTextField
                            name={`products[${prodIndex}].customerOrderId`}
                          />
                        ) : (
                          prod.customerOrderId
                        )}
                      </TableCell>

                      <TableCell
                        //   component="th"
                        scope="row"
                        style={{ ...styles.productsBody, fontWeight: "bold" }}
                      >
                        {prod.styleNo}
                        <CustomCopyIcon text={prod.styleNo} />
                      </TableCell>
                      <TableCell
                        style={{ ...styles.productsBody, fontWeight: "bold" }}
                      >
                        {prod.quantity}
                      </TableCell>

                      <TableCell
                        style={{
                          ...styles.productsBody,
                          whiteSpace: "pre-line",
                        }}
                        scope="row"
                      >
                        {`${
                          isNaN(prod.grossWeight)
                            ? ""
                            : prod.grossWeight.toFixed(3)
                        }\n${
                          isNaN(prod.netWeight) ? "" : prod.netWeight.toFixed(3)
                        }`}
                      </TableCell>
                      <TableCell
                        style={{
                          ...styles.productsBody,
                          whiteSpace: "pre-line",
                        }}
                        scope="row"
                      >
                        {`${prod.metalType}\n${displayMeltingAsPerClient(
                          prod.metalPurity
                        )}`}
                      </TableCell>
                      <TableCell style={styles.productsBody}>
                        {isEditable && !isVendor ? (
                          <RHTextField name={`products[${prodIndex}].size`} />
                        ) : (
                          prod.size
                        )}
                      </TableCell>

                      <TableCell scope="row" style={styles.productsBody}>
                        {prod.exhibitionRemarks || "-"}
                        {prod.exhibitionRemarks && (
                          <CustomCopyIcon text={prod.exhibitionRemarks} />
                        )}
                      </TableCell>
                      <TableCell scope="row" style={styles.productsBody}>
                        {isEditable && !isVendor ? (
                          <RHTextField
                            name={`products[${prodIndex}].adminRemarks`}
                          />
                        ) : (
                          <div>
                            {prod?.adminRemarks || "-"}{" "}
                            {prod.adminRemarks && (
                              <CustomCopyIcon text={prod.adminRemarks} />
                            )}
                          </div>
                        )}
                      </TableCell>
                      <TableCell scope="row" style={styles.productsBody}>
                        {isEditable && !isVendor ? (
                          // &&isVendorCodeEditable(product)
                          <RHAutoComplete
                            disableClearable
                            options={vendorCodes || []} //
                            size="small"
                            style={{ width: "120px" }}
                            name={`products[${prodIndex}].vendorCode`}
                            disabled={
                              prod.orderStatus !==
                              enums["vendor-portal"]?.orderStatus.pending
                            }
                          />
                        ) : (
                          prod?.vendorCode
                        )}
                      </TableCell>
                      <TableCell style={styles.productsBody}>
                        {isEditable && !isVendor ? (
                          <RHDatePicker
                            name={`products[${prodIndex}].dueDate`}
                            label={"Due Date"}
                          />
                        ) : prod.dueDate ? (
                          formatDateToRead(prod.dueDate)
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell scope="row" style={styles.productsBody}>
                        {isEditable ? (
                          <RHAutoComplete
                            disableClearable={true}
                            style={{
                              width: "120px",
                              textAlign: "center",
                              padding: "8px",
                            }}
                            options={
                              Object.values(
                                enums["vendor-portal"]?.orderStatus
                              ).filter((status) =>
                                isVendor
                                  ? !(
                                      status ===
                                        enums["vendor-portal"]?.orderStatus
                                          ?.assigned ||
                                      status ===
                                        enums["vendor-portal"]?.orderStatus
                                          ?.pending
                                    )
                                  : true
                              ) || []
                            }
                            name={`products[${prodIndex}].orderStatus`}
                            onChange={() => {
                              setValue(
                                `products[${prodIndex}].dateModified`,
                                new Date()
                              );
                            }}
                          />
                        ) : (
                          prod.orderStatus
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </FormProvider>
  );
}

// Row.propTypes = {
//   row: PropTypes.shape({
//     calories: PropTypes.number.isRequired,
//     carbs: PropTypes.number.isRequired,
//     fat: PropTypes.number.isRequired,
//     history: PropTypes.arrayOf(
//       PropTypes.shape({
//         amount: PropTypes.number.isRequired,
//         customerId: PropTypes.string.isRequired,
//         date: PropTypes.string.isRequired,
//       })
//     ).isRequired,
//     name: PropTypes.string.isRequired,
//     price: PropTypes.number.isRequired,
//     protein: PropTypes.number.isRequired,
//   }).isRequired,
// };

export default CollapseList;

const styles = {
  productsColumns: {
    backgroundColor: "#50C078",
    color: "white",
  },
  productsBody: {
    backgroundColor: "#AFE1AF",
  },
};
