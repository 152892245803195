import { Grid } from "@material-ui/core";
import Label from "@ui/components/Label";
import { globalUserControl } from "@ui/ComponentUtils/blueprintAPIs";
import { globalDateFormat, RHCheckbox } from "@ui/ReactHookFormControls/index";
import RHAsyncAutoComplete from "@ui/ReactHookFormControls/RHAsyncAutoComplete";
import RHAutoComplete from "@ui/ReactHookFormControls/RHAutoComplete";
import RHDocUpload from "@ui/ReactHookFormControls/RHDocUpload";
import RHTextField from "@ui/ReactHookFormControls/RHTextField";
import CustomEventEmitter from "@ui/Utils/CustomEventEmitter";
import commonConfig from "config/commonConfig";
import ExcelJS from "exceljs";
import { isUndefined } from "lodash";
import moment from "moment";
import React, { Fragment } from "react";
import Resizer from "react-image-file-resizer";
import enums from "../../helpers/enums";
import {
  alphabets,
  downloadExcelFile,
  excelAlignment,
  excelFont,
  getCellStyle,
  getExcelDropdown,
  sheetNames,
} from "./excelImportHelpers";
import HTTP from "./http";
import oopsImage from "../assets/commonIcons/notFoundIcons/oopsNotFound.png";
import imageShutdown from "../assets/commonIcons/notFoundIcons/image-shutdown.png";

export const oopsNotFound = oopsImage;
export const imageExpired = imageShutdown;

const base64toFile = (base64, filename) => {
  let arr = base64.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const base64ToBlobUrl = (base64) => {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: "application/pdf" });
  return URL.createObjectURL(blob);
};

const resizeFile = (file, type) => {
  // console.log(Resizer);
  // if (!Resizer?.imageFileResizer) {
  //   return file;
  // }

  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      1024,
      1024,
      "JPEG",
      100,
      0,
      (uri) => {
        if (type === "base64") resolve(uri);
        const resizedImage = base64toFile(uri, file.name);
        resolve(resizedImage);
      },
      "base64"
    );
  });

  // Resizer.imageFileResizer(
  //   file, // Is the file of the image which will resized.
  //   maxWidth, // Is the maxWidth of the resized new image.
  //   maxHeight, // Is the maxHeight of the resized new image.
  //   compressFormat, // Is the compressFormat of the resized new image.
  //   quality, // Is the quality of the resized new image.
  //   rotation, // Is the degree of clockwise rotation to apply to uploaded image.
  //   responseUriFunc, // Is the callBack function of the resized new image URI.
  //   outputType, // Is the output type of the resized new image.
  //   minWidth, // Is the minWidth of the resized new image.
  //   minHeight // Is the minHeight of the resized new image.
  // );
};

const downloadFile = (item) => {
  try {
    const a = document.createElement("a");
    a.href = item?.signedUrl;
    a.download = `${item?.fileName}.${item?.fileType?.split("/")[1]}`;
    a.target = "hiddenIframe";
    a.click();
  } catch (error) {
    console.log(error);
  }
};

const getNestedObjValue = (obj, nestedKey) => {
  if (obj && nestedKey) {
    const keys = nestedKey?.toString().split(".") || [];
    let value = { ...obj };

    keys.map((key) => (value = value?.[key] || ""));

    return value;
  }
  return "";
};

export const fetchLocationName = async (lat, lng) => {
  try {
    let response = await HTTP.get(
      `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${lat}&longitude=${lng}&localityLanguage=en`
    );

    return `${response?.data?.locality}, ${response?.data?.city}, ${response?.data?.countryName}`;
  } catch (err) {
    console.log(err);
    return "";
  }
};

const getRHFieldComponent = (field, { isEditable, disableFields, values }) => {
  switch (field.fieldType) {
    case "upload":
      return field.hidden ? null : (
        <>
          <RHDocUpload
            required={field.required}
            onVerify={field.onVerify}
            code={field.code}
            previewThumbnail={field.previewThumbnail}
            single={field.single}
            isEditable={isEditable}
            model={field.model}
            previewStyle={field.previewStyle}
            name={field.name}
            label={field.label}
            additionalPath={field.additionalPath}
            dataEngine={field.dataEngine}
            replace={field.replace}
            accept={field.accept || "*"}
            multiple={field.multiple}
            onChange={field.onChange || (() => {})}
            disabled={!isEditable || disableFields}
          />
        </>
      );

    case "textField":
      let value =
        typeof field.render === "function"
          ? field.render(values)
          : field.render
          ? getNestedObjValue(values, field.render)
          : getNestedObjValue(values, field.name);

      if (field.hash) {
        value = "**********";
      }

      return field.hidden ? null : !isEditable || field.readOnly ? (
        <Label
          value={value}
          label={
            field.weightField
              ? `${field.label} (${field.inputInCts ? "cts" : "g"})`
              : field.label
          }
          valueStyle={field.valueStyle}
          labelStyle={field.labelStyle}
        />
      ) : (
        <RHTextField
          name={field.name}
          label={
            field.weightField
              ? `${field.label} (${field.inputInCts ? "cts" : "g"})`
              : field.label
          }
          type={field?.dataType}
          inputProps={field.weightField || field.priceField ? { min: "0" } : {}}
          onChange={field.onChange || (() => {})}
          disabled={disableFields}
          multiline={field.multiline}
          rows={field.rows}
          placeholder={field.placeholder}
          style={field.style}
          onKeyDown={field.onKeyDown}
          autoComplete={field.autoComplete}
        />
      );

    case "dropdown":
      return field.hidden ? null : !isEditable ||
        field.readOnly ||
        disableFields ? (
        <Label
          value={
            field.multiple
              ? (values?.[field.name]?.map
                  ? values?.[field.name]
                  : [values?.[field.name]]
                )
                  ?.map((ele) => {
                    return field.render === "firstName"
                      ? (ele?.firstName || "") +
                          " " +
                          (ele?.lastName || "") +
                          (ele?.phone ? ` (${ele?.phone})` : "")
                      : field.render
                      ? ele?.[field.render]
                      : ele;
                  })
                  ?.join(", ")
              : typeof field.render === "function"
              ? field.render()
              : field.render
              ? getNestedObjValue(values, field.render)
              : values?.[field.name]
          }
          label={field.label}
        />
      ) : (
        <RHAutoComplete
          selectOnFocus={field.selectOnFocus}
          clearOnBlur={field.clearOnBlur}
          freeSolo={field.freeSolo}
          filterOptions={field.filterOptions}
          disableClearable={field.disableClearable}
          extraOptions={field.extraOptions}
          groupBy={field.groupBy}
          renderFunc={field.renderFunc}
          required={field.required}
          defaultValue={field.defaultValue}
          multiple={field.multiple}
          name={field.name}
          label={field.label}
          onChange={field.onChange || (() => {})}
          options={field.options || []}
          getOptionLabel={field.getOptionLabel}
          getOptionSelected={field.getOptionSelected}
          render={field.render}
          renderOption={field.renderOption}
          disabled={disableFields}
          autoComplete={field.autoComplete}
          autoSelect={field.autoSelect}
        />
      );

    case "asyncDropdown":
      return field.hidden ? null : !isEditable || disableFields ? (
        <Label
          value={
            field.multiple
              ? values?.[field.name]
                  ?.map((ele) => {
                    return field.render === "firstName"
                      ? (ele?.firstName || "") + " " + (ele?.lastName || "")
                      : field.render
                      ? ele?.[field.render]
                      : ele;
                  })
                  ?.join(", ")
              : typeof field.render === "function"
              ? field.render(values)
              : field.render
              ? getNestedObjValue(values, field.render)
              : values?.[field.name]
          }
          label={field.label}
        />
      ) : (
        <RHAsyncAutoComplete
          selectOnFocus={field.selectOnFocus}
          clearOnBlur={field.clearOnBlur}
          freeSolo={field.freeSolo}
          filterOptions={field.filterOptions}
          disableClearable={field.disableClearable}
          extraOptions={field.extraOptions}
          groupBy={field.groupBy}
          renderFunc={field.renderFunc}
          required={field.required}
          defaultValue={field.defaultValue}
          multiple={field.multiple}
          name={field.name}
          label={field.label}
          onChange={field.onChange}
          options={field.options || []}
          getOptionLabel={field.getOptionLabel}
          getOptionSelected={field.getOptionSelected}
          render={field.render}
          renderOption={field.renderOption}
          disabled={disableFields}
          apiUrl={field.apiUrl}
          apiMethod={field.apiMethod}
          apiBody={field.apiBody}
          autoSelect={field.autoSelect}
          autoComplete={field.autoComplete}
        />
      );

    case "featureCheckbox":
      return field.hidden ? null : (
        <>
          <Label label={field.label} noColon />

          <Grid container spacing={2}>
            {field.features?.map((feature, featureIndex) =>
              feature.hidden ? null : (
                <Fragment key={featureIndex}>
                  <Grid item xs={1}>
                    <RHCheckbox
                      disabled={feature.disabled || !isEditable}
                      name={field.name + "." + feature.name}
                    />
                  </Grid>
                  <Grid item xs={11}>
                    <Label
                      label={feature.label}
                      noColon
                      labelStyle={{ paddingTop: "12px" }}
                    />
                  </Grid>
                </Fragment>
              )
            )}
          </Grid>
        </>
      );

    default:
      return field.hidden ? null : (
        <>
          <Label
            value={
              getNestedObjValue(values, field.name) ||
              values?.[field.name] ||
              field.value
            }
            label={field.label}
          />
          {field.copyToClipboard && (
            <button
              onClick={() => {
                var text = values?.[field.name] || field.value || "";
                navigator.clipboard.writeText(text).then(
                  function () {
                    console.log("Async: Copying to clipboard was successful!");
                    CustomEventEmitter.emit(
                      "alert_success",
                      "Copied to clipboard!"
                    );
                  },
                  function (err) {
                    console.error("Async: Could not copy text: ", err);
                  }
                );
              }}
            >
              Copy to clipboard
            </button>
          )}
        </>
      );
  }
};

const localStorageUser = window.localStorage.getItem("userDetails");
const sessionStorageUser = window.sessionStorage.getItem("userDetails");

let obj =
  localStorageUser &&
  !(localStorageUser == "null" || localStorageUser == "undefined")
    ? localStorageUser
    : sessionStorageUser && // only used in LINK it for now... take care later
      !(sessionStorageUser == "null" || sessionStorageUser == "undefined")
    ? sessionStorageUser
    : "{}";
obj = obj ? JSON.parse(obj) : {};

const userDetails = {
  ...obj,
  fullName: (obj?.firstName || "") + " " + (obj?.lastName || ""),
  client: {
    ...obj?.client,
    premiumClient: {
      ...obj?.client?.premiumClient,
      unlimitedBucketStorage:
        obj?.client?.premiumClient?.unlimitedBucketStorage,
    },
  },
};

export const isDivisionControlled =
  userDetails?.client?.premiumClient?.divisionControl;

export const userDivision = userDetails?.division;

const isSuperAdmin = userDetails?.role === "Super Admin";
const isAdmin = userDetails?.role === enums.roles.admin;
export const isCustomer = userDetails?.role === enums.roles.customer;
export const isVendor = userDetails?.role === enums.roles.vendor;

export const userIdentifier = {
  isSuperAdmin,
  isAdmin,
  isVendor,
  isCustomer,
  isZoneManager: userDetails?.role === enums.roles.zoneManager,
  isSalesPerson: userDetails?.role === enums.roles.salesPerson,
  isSalesHead: userDetails?.role === enums.roles.salesHead,
};

export const subscription = {
  cortex: (userDetails?.client?.appModules || []).find(
    (appModule) => appModule.key === "cortex"
  ),
};

// ref: https://stackoverflow.com/questions/39997067/es6-unique-array-of-objects-with-set
export const uniqueArray = (a = []) => {
  return [...new Set(a.map((o) => JSON.stringify(o)))].map((s) =>
    s ? JSON.parse(s) : ""
  );
};

export const formatDateToRead = (date, format = globalDateFormat.read) => {
  return moment(date).format(format);
};

export const formatNumberToRead = (number, { inputInCts = false } = {}) => {
  return Number(number).toLocaleString("en-IN", {
    maximumFractionDigits: inputInCts ? 2 : 3,
  });
};

export const formatWeight = (weightValue, { inputInCts = false } = {}) => {
  if (inputInCts) {
    return `${formatNumberToRead(weightValue, { inputInCts: true })} cts`;
  } else if (weightValue >= 10000) {
    return `${formatNumberToRead(weightValue / 1000)} kg`;
  } else {
    return `${formatNumberToRead(weightValue)} g`;
  }
};

export const generatePDF = async (pdfData, fileNameWithoutExt) => {
  try {
    const linkSource = `data:application/pdf;base64,${pdfData}`;

    const downloadLink = document.createElement("a");
    const fileName = `${fileNameWithoutExt}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
    CustomEventEmitter.emit("alert_success", "Download Successfull");
  } catch (err) {
    CustomEventEmitter.emit("alert_error", "Something went wrong");
  }
};

export const copyToClipboard = async (text) => {
  try {
    const permissionStatus = await navigator.permissions.query({
      name: "clipboard-write",
    });

    if (
      permissionStatus.state === "granted" ||
      permissionStatus.state === "prompt"
    ) {
      // Request permission and copy to clipboard if granted
      navigator.clipboard.writeText(text).then(
        function () {
          console.log("Async: Copying to clipboard was successful!");
          CustomEventEmitter.emit("alert_success", "Copied to clipboard");
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    } else {
      console.error("Clipboard permission denied");
      CustomEventEmitter.emit("alert_error", "Clipboard permission denied");
    }
  } catch (err) {
    console.error("Permission query failed: ", err);
    CustomEventEmitter.emit("alert_error", "Permission query failed");
  }
};

// CHECK IF IMAGE EXISTS
export const checkIfImageExists = (url) => {
  return new Promise(function (resolve) {
    const img = new Image();
    img.src = url;

    if (img.complete) {
      resolve(true);
    } else {
      img.onload = () => {
        resolve(true);
      };

      img.onerror = () => {
        resolve(false);
      };
    }
  });
};

const division = window.sessionStorage.getItem("division");

export const selectedDivisionId = division
  ? isUndefined(division) || division === "undefined" || division === "null"
    ? {}
    : JSON.parse(division)?._id
  : "";
export const selectedDivisionName = division
  ? isUndefined(division) || division === "undefined" || division === "null"
    ? {}
    : JSON.parse(division)?.name
  : "";

export const getCortexImageUri = ({
  fileName,
  imageFlag,
  client,
  division,
  model = "training",
} = {}) =>
  imageFlag
    ? `https://storage.googleapis.com/${commonConfig.moduleBuckets?.cortex}/${
        enums.keywords.cortex
      }/${userDetails?.client?._id || client?._id || client || "clientLess"}/${
        isDivisionControlled || division?._id
          ? `${division?._id || selectedDivisionId}/`
          : ""
      }${model}/${fileName}`
    : oopsNotFound;

export const getProductImageUri = ({ defaultImage } = {}) =>
  defaultImage?.signedUrl || oopsNotFound;

export const getDesignImageUri = (styleNo) =>
  `https://storage.googleapis.com/${commonConfig.dataEngineBucketName}/${
    enums.keywords.dataEngine
  }/${userDetails?.client?._id || "clientLess"}/${
    isDivisionControlled ? `${selectedDivisionId}/` : ""
  }products/${styleNo}.png`;

export const getValidDesignImageUri = async (
  styleNo,
  { imageNotFound, oopsNotFound } = {}
) => {
  const location = `https://storage.googleapis.com/${
    commonConfig.dataEngineBucketName
  }/${enums.keywords.dataEngine}/${userDetails?.client?._id || "clientLess"}/${
    isDivisionControlled ? `${selectedDivisionId}/` : ""
  }products/${styleNo}.png`;

  const exist = await checkIfImageExists(location);

  let altLoc = oopsNotFound;

  if (imageNotFound) {
    altLoc =
      "https://storage.googleapis.com/sheen-upload/Assets/Common/image-not-found.jpg";
  } else if (oopsNotFound) {
    altLoc = oopsNotFound;
  }

  return exist ? location : altLoc;
};

export const defaultGoldHex = "#cc9e04";
export const defaultLightGoldHex = "#fcb823";
export const defaultLighterGoldHex = "#ffd578";
export const defaultLightestGoldHex = "#ffe09c";

export const downloadDGXL = function (e) {
  try {
    e.grid.downloadDataAsCSV();
  } catch (err) {
    console.log(err);
  }
};

export const regexPurify = (element) => {
  // Escaping Special Characters for $regex in mongoose search
  return (element && element.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")) || "";
};

export const roundOff = (value, { weight, isDiamond } = {}) => {
  if (weight) {
    if (isDiamond) {
      return Number(Number(value).toFixed(2));
    }
    return Number(Number(value).toFixed(3));
  }

  return Number(Number(value).toFixed(2));
};

export const disableUpdateProduct = ({ stockStatus }) =>
  !(
    stockStatus === enums.stockStatus.staged ||
    stockStatus === enums.stockStatus.inventory
  );

export const getLowestOrderStatus = (items) => {
  let availableStats = (items || [])?.map((item) => item?.orderStatus);
  availableStats = uniqueArray(availableStats);
  const statusEnums = Object.values(enums["vendor-portal"].orderStatus); // if you get an error in this line, just add orderStatus key in your web/enums.js file
  let lowest = statusEnums.indexOf(availableStats[0]);

  availableStats.map((status, statusIndex) => {
    let currentStatusRank = statusEnums.indexOf(status);
    if (currentStatusRank < lowest) {
      lowest = statusIndex;
    }
  });

  return statusEnums[lowest];
};

export {
  base64toFile,
  downloadFile,
  getNestedObjValue,
  getRHFieldComponent,
  isAdmin,
  isSuperAdmin,
  resizeFile,
  userDetails,
};

// #Less Goooooooo...

export const handleGlobalUserControl = async (
  // By Default this function signsout all Users according to appModules param value
  action = { selectAll: true, users: [] },
  // hidden iamControl.. Dont set default values for iamControl Object..
  // till now; const { disabled } = iamControl.,
  appModules = [] // array of appModule keys.. ["data-engine", "e-creatives"]
) => {
  try {
    let message = { future: "Signout ", past: "Signed out " };
    if (action.iamControl) {
      if (action.iamControl.disabled)
        message = { future: "Disable", past: "Disabled " };
      else message = { future: "Approve ", past: "Approved " };
    }

    if (
      window.confirm(
        `Are you sure to Globally ${message.future} ${
          action.selectAll
            ? `ALL Users in [${appModules.join(", ")}] Projects?`
            : `[${(action.users || [])
                .map((user) => user?.firstName + " " + user?.lastName)
                .join(", ")}] ?`
        }`
      )
    ) {
      let modifiedUsersCount = 0;

      await Promise.all(
        appModules.map(async (appKey) => {
          const res = await globalUserControl(`${appKey}/users`, action);
          modifiedUsersCount += Number(res.nModified);
        })
      );

      CustomEventEmitter.emit(
        "alert_success",
        `${message.past} ${modifiedUsersCount}  Users successfully`
      );
    }
  } catch (err) {
    console.log(err);
    CustomEventEmitter.emit(
      "alert_error",
      err?.response?.data?.message || "Something went wrong..."
    );
    throw err;
  }
};

export const userAccessMenus = (routes) => {
  routes = routes.filter((route) => {
    if (route.path?.toString().toLowerCase().match("dashboard")) {
      return true;
    }

    return isAdmin || isSuperAdmin;
  });
};

// remove below function ASAP
export const userHasAccess = () => {
  const location = window.location.href;
  const appModule = location.split("/")[4],
    entityName = location.split("/")[5];
  const isDashboard = location.toLowerCase().match("dashboard");

  // switch (appModule) {
  //   case "e-creatives":
  //     const screenAccess = isDashboard ? true : isAdmin || isSuperAdmin;

  //     return {
  //       screen: screenAccess,
  //     };
  //   case "data-engine":
  //     const screen = isDashboard ? true : isAdmin || isSuperAdmin;

  //     return {
  //       screen,
  //     };

  //   case "vendor-portal":
  //     const access =

  //     return {
  //       screen: access,
  //     };
  // }

  return { screen: isDashboard ? true : isAdmin || isSuperAdmin };
};

export const delay = async (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const connectPrinter = async () => {
  try {
    const device = await navigator.usb.requestDevice({ filters: [] });
    // Handle the connected printer device
    console.log("Connected device:", device);

    return device;
  } catch (error) {
    console.error("Error connecting to printer:", error);
    throw error;
  }
};

export const printFromConnectedDevice = async (
  device,
  command,
  // [
  //   "SIZE 48 mm,25 mm",
  //   "CLS",
  //   'TEXT 30,10,"4",0,1,1,"HackerNoon"',
  //   'TEXT 30,50,"2",0,1,1,"WebUSB API"',
  //   'BARCODE 30,80,"128",70,1,0,2,2,"altospos.com"',
  //   "PRINT 1",
  //   "END",
  // ];
  { refresh } = {}
) => {
  try {
    if (device?.open && command) {
      const cmds = command;
      await device.open();
      await device.selectConfiguration(1);
      await device.claimInterface(0);
      await device.transferOut(
        device.configuration.interfaces[0].alternate.endpoints.find(
          (obj) => obj.direction === "out"
        ).endpointNumber,
        new Uint8Array(new TextEncoder().encode(cmds.join("\r\n")))
      );
      await device.close();

      return;
    } else {
      CustomEventEmitter.emit(
        "alert_error",
        `${
          !device?.open
            ? "Connect printer to continue"
            : "Print command should not be empty"
        }`
      );
    }
    throw {
      message: `${
        !device?.open
          ? "Connect printer to continue"
          : "Print command should not be empty"
      }`,
    };
  } catch (err) {
    console.log("Error while printing", err);
    throw err;
  }
};

// for material table usage in options prop:
/* exportButton: {
  pdf: true,
  csv: true
},
exportCsv: (data, columns) => exportExcel([{data, columns, sheetName: "Stocks"}], "Stocks"),
*/

export const exportExcel = async (
  pages = [
    {
      data: [
        {
          // [name]: value
        },
      ],
      columns: [{ title: "", field: "", width: 20 }],
      sheetName: "Data",
    },
  ],
  fileName = "Report: " + formatDateToRead(new Date()),
  { raw = false } = {}
) => {
  try {
    const defaultRowHeight = {
      header: 25,
    };
    const workbook = new ExcelJS.Workbook();

    for (let i = 0; i < pages.length; i++) {
      const currentPage = pages[i];
      const data = currentPage.data;
      const columns = currentPage.columns;

      const currentSheet = workbook.addWorksheet(
        currentPage.sheetName || `sheet${i + 1}`
      );

      currentSheet.getRow("1").height = defaultRowHeight.header;
      currentSheet.columns = columns.map((col) => ({
        header: col.title,
        width: col.width,
      }));

      let excelRow = 2;

      if (raw && currentPage.sheetName === sheetNames.diamondDetails) {
        data.map((diamondRow, diamondRowIndex) => {
          columns.map((col, colIndex) => {
            currentSheet.getCell(alphabets[colIndex] + excelRow).value =
              getNestedObjValue(diamondRow, col.field) + (col.extra || "");
          });

          excelRow++;
        });
      } else if (currentPage.sheetName === sheetNames.diamondDetails) {
        data.map((row) => {
          (row.diamondDetails || []).map((diamondRow) => {
            let rowValue = "";
            columns.map((col, colIndex) => {
              if (col.field === "sku") {
                rowValue = row.sku;
              } else {
                rowValue = getNestedObjValue(diamondRow, col.field);
              }

              currentSheet.getCell(alphabets[colIndex] + excelRow).value =
                rowValue;
            });
            excelRow++;
          });
        });
      } else {
        data.map((row) => {
          columns.map((col, colIndex) => {
            col.field = col.field
              ?.toString()
              .replace(" (perWeight)", "")
              .replace(" (perPiece)", "");

            currentSheet.getCell(alphabets[colIndex] + excelRow).value =
              getNestedObjValue(row, col.field) + (col.extra || "");
          });

          excelRow++;
        });
      }

      // Design columns
      for (let colIndex = 0; colIndex < columns.length; colIndex++) {
        // Styling only HEADERS
        currentSheet.getCell(`${alphabets[colIndex] + 1}`).style = getCellStyle(
          excelFont.size.headers,
          excelFont.color.headers,
          excelFont.bold.headers
        );

        currentSheet.getColumn(`${alphabets[colIndex]}`).alignment = {
          wrapText: excelAlignment.body.wrap,
        };

        currentSheet.getCell(`'${alphabets[colIndex] + 1}'`).alignment = {
          vertical: excelAlignment.headers.vertical,
          horizontal: excelAlignment.headers.horizontal,
          wrapText: true,
        };

        if (columns[colIndex].options?.length) {
          currentSheet.dataValidations.add(
            `${alphabets[colIndex]}2:${alphabets[colIndex]}999999`,
            getExcelDropdown(columns[colIndex].options)
          );
        }
      }
    }

    downloadExcelFile(workbook, fileName);
  } catch (err) {
    console.log(err);
    CustomEventEmitter.emit("alert_error", "Something went wrong!");
  }
};

// const dataUrlToFile = async (dataUrl, fileName, mimeType) => {
//   // console.log({ dataUrl, fileName, mimeType });
//   const res = await axios(dataUrl);
//   const blob = res.data;
//   return new File([blob], fileName, { type: mimeType });
// };

export const getCleanedObjectWithTheseKeys = (
  mainObject,
  { keysToRemove = ["_id"], removeNullValues = true }
) => {
  const cleanObject = (obj) => {
    if (obj === null) {
      return null;
    }

    if (Array.isArray(obj)) {
      const cleanedArray = obj.map((item) => cleanObject(item)).filter(Boolean);
      return cleanedArray.length > 0 ? cleanedArray : null;
    }

    if (typeof obj !== "object") {
      return obj;
    }

    const newObj = {};

    Object.keys(obj).forEach((key) => {
      if (
        Array.isArray(keysToRemove) &&
        keysToRemove.some(
          (removeKey) => removeKey.toLowerCase() === key.toLowerCase()
        )
      ) {
        return;
      }

      const cleanedValue = cleanObject(obj[key]);

      if (removeNullValues && cleanedValue === null) {
        return;
      }

      newObj[key] = cleanedValue;
    });

    return Object.keys(newObj).length > 0 ? newObj : null;
  };

  return cleanObject(mainObject);
};

/* 
Feb 24, 2024, 00:05:03

export const getCleanedObjectWithThisKey = (
  mainObject,
  keyToRemove = "_id"
) => {
  // Recursive with Style :B

  const mainObjectKeys = Object.keys(mainObject),
    object = { ...mainObject };

  for (let o = 0; o < mainObjectKeys.length; o++) {
    const mainObjectKey = mainObjectKeys[o];

    if (mainObjectKey === keyToRemove) {
      delete object[mainObjectKey];
    } else if (object[mainObjectKey]?.[keyToRemove]) {
      delete object[mainObjectKey][keyToRemove];
    } else if (
      typeof object[mainObjectKey] !== "string" &&
      Array.isArray(object[mainObjectKey])
    ) {
      object[mainObjectKey] = object[mainObjectKey].map((childObject) => {
        return getCleanedObjectWithThisKey(childObject, keyToRemove);
      });
    }
  }

  return object;
};

*/

export const getCleanedObject = (data) => {
  if (Array.isArray(data)) {
    const cleanedArray = data
      .map((item) => getCleanedObject(item))
      .filter((item) => item !== undefined);
    return cleanedArray.length > 0 ? cleanedArray : undefined;
  }

  if (typeof data === "object" && data !== null) {
    const result = {};

    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        const value = data[key];
        const cleanedValue = getCleanedObject(value);

        if (cleanedValue !== undefined && cleanedValue !== "") {
          result[key] = cleanedValue;
        }
      }
    }

    return Object.keys(result).length > 0 ? result : undefined;
  }

  // For non-object values (including null and empty strings), return the value as is
  return data;
};

export const handleCloneRecord = (
  navigate,
  values,
  { model, removeFields = [] },
  message
) => {
  try {
    values = values || {};

    (removeFields || []).map((field) => delete values[field]);

    values = getCleanedObject(values);

    navigate(`/${model}`, { state: { formData: values, isCloned: true } });

    CustomEventEmitter.emit(
      "alert_success",
      message?.success
        ? message.success
        : "Cloned data ready to Create New Records"
    );
  } catch (err) {
    CustomEventEmitter.emit("alert_error", JSON.stringify(err));
  }
};

export const camelCaseToReadable = (inputString) => {
  if (inputString) {
    // Replace capital letters with space followed by the lowercase letter
    const resultString = inputString.replace(/([A-Z])/g, " $1");

    // Capitalize the first letter and remove leading space
    return resultString.charAt(0).toUpperCase() + resultString.slice(1).trim();
  } else return inputString;
};

export const findDuplicates = (arr, key) => {
  const uniqueSet = new Set();
  const duplicates = [];

  for (const item of arr) {
    const value = item[key] || item;
    if (uniqueSet.has(value)) {
      duplicates.push(value);
    } else {
      uniqueSet.add(value);
    }
  }

  return duplicates;
};

export const joinWithSeparator = (values = [], separator = ", ") => {
  const filteredValues = values.filter((value) => value && value.trim() !== "");
  return filteredValues.join(separator);
};

export const scrollToTop = () => {
  window.scrollTo({ top: 0, behavior: "smooth" });
};

export const formatSecondsToRead = (seconds) => {
  const months = Math.floor(seconds / (30 * 24 * 60 * 60)); // Approximate months
  seconds -= months * (30 * 24 * 60 * 60);

  const weeks = Math.floor(seconds / (7 * 24 * 60 * 60));
  seconds -= weeks * (7 * 24 * 60 * 60);

  const days = Math.floor(seconds / (24 * 60 * 60));
  seconds -= days * (24 * 60 * 60);

  const hours = Math.floor(seconds / (60 * 60));
  seconds -= hours * (60 * 60);

  const minutes = Math.floor(seconds / 60);
  seconds -= minutes * 60;

  let result = "";

  if (months > 0) {
    result += `${months} ${parseInt(months) === 1 ? "month" : "months"}, `;
  }
  if (weeks > 0) {
    result += `${weeks} ${parseInt(weeks) === 1 ? "week" : "weeks"}, `;
  }
  if (days > 0) {
    result += `${days} ${parseInt(days) === 1 ? "day" : "days"}, `;
  }
  if (hours > 0) {
    result += `${hours} ${parseInt(hours) === 1 ? "hour" : "hours"}, `;
  }
  if (minutes > 0) {
    result += `${minutes} ${parseInt(minutes) === 1 ? "minute" : "minutes"}, `;
  }
  if (seconds > 0 || result === "") {
    result += `${seconds} ${parseInt(seconds) === 1 ? "second" : "seconds"}`;
  }

  // Remove trailing comma if present
  if (result.endsWith(", ")) {
    result = result.slice(0, -2);
  }

  return result;
};

export const formatIndianNumber = (number) => {
  if (number === null || number === undefined) return "";

  const [integerPart, decimalPart] = number.toString().split(".");

  const lastThreeDigits = integerPart.slice(-3);
  const otherDigits = integerPart.slice(0, -3);

  const formattedIntegerPart =
    otherDigits !== ""
      ? otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
        "," +
        lastThreeDigits
      : lastThreeDigits;

  return decimalPart
    ? formattedIntegerPart + "." + decimalPart
    : formattedIntegerPart;
};

export const isObjectDirty = (originalObj, currentObj) => {
  // Convert objects to JSON strings
  const originalStr = JSON.stringify(originalObj);
  const currentStr = JSON.stringify(currentObj);

  // Compare the strings
  return originalStr !== currentStr;
};

export const isDecimal = (value) => {
  // Ensure the value is a number and not an integer
  return Number(value) % 1 !== 0;
};

export const toTitleCase = (str) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};
