import React, { Fragment, useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Typography,
  makeStyles,
  ThemeProvider,
  createTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { MyCarousel } from "@ui/MuiComponents/index";
import {
  formatDateToRead,
  getProductImageUri,
  imageExpired,
  isAdmin,
  isSuperAdmin,
  isVendor,
  oopsNotFound,
  roundOff,
} from "@ui/Utils/helper";
import Label from "@ui/components/Label";
import RHAutoComplete from "@ui/ReactHookFormControls/RHAutoComplete";
import enums from "helpers/enums";
import { defaultFilter } from "../Detail";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { TableHead } from "@material-ui/core/index";
import RHDatePicker from "@ui/ReactHookFormControls/RHDatePicker";
import RHTextField from "@ui/ReactHookFormControls/RHTextField";
import { Button } from "@material-ui/core/index";
import { useFieldArray, useFormContext } from "react-hook-form";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import RemoveCircleOutlineOutlinedIcon from "@mui/icons-material/RemoveCircleOutlineOutlined";
import MyHoverIcons from "@ui/assets/commonIcons/customIcons/MyHoverIcons";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LazyLoad from "@ui/Actions/LazyLoad/index";

const useStyles = makeStyles((theme) => ({
  subCategoryHeader: {
    backgroundColor: "#3ebfc2",
    color: "white",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  SubCategoryGroup: {
    backgroundColor: "#F2F1FF",
    color: "white",
    padding: theme.spacing(2),
  },
  productItem: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    boxShadow: theme.shadows[4],
    borderRadius: theme.shape.borderRadius,
    transition: "box-shadow 0.3s ease-in-out",
    "&:hover": {
      boxShadow: theme.shadows[8],
    },
  },
  previewImage: {
    width: "80px",
    height: "80px",
    cursor: "pointer",
    borderRadius: theme.shape.borderRadius,
  },
  accordionHeading: {
    fontFamily: "monospace",
    fontWeight: "bold",
    fontSize: theme.typography.pxToRem(20),
  },
  productTable: {
    minWidth: 650,
  },
  productImage: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    cursor: "pointer",
  },
}));

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#2196F3",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#64B5F6",
      contrastText: "#ffffff",
    },
    background: {
      paper: "#ffffff",
    },
  },
  shadows: Array(25).fill("none"),
});

const CategoryGroup = ({
  categoryGroupItem,
  categoryGroupItemIndex,
  isEditable,
  vendorCodes,
}) => {
  const classes = useStyles();

  const expectedCount = (categoryGroupItem.subCategories || []).reduce(
    (groupAcc, subCatGroup) => {
      return groupAcc + (subCatGroup?.quantity || 0);
    },
    0
  );

  const orderedCount = (categoryGroupItem?.subCategories || []).reduce(
    (groupAcc, subCategory) => {
      const subCategoryQuantity = (subCategory?.items || []).reduce(
        (itemAcc, item) => itemAcc + (item?.quantity || 0),
        0
      );
      return groupAcc + subCategoryQuantity;
    },
    0
  );

  return (
    <ThemeProvider theme={customTheme}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Accordion fullWidth>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Grid container justify="space-between">
                <Grid item xs={3}>
                  <Typography className={classes.accordionHeading} variant="h6">
                    # {categoryGroupItem?.category?.name || "-"}
                  </Typography>
                </Grid>

                <Grid item xs={2}>
                  {!isVendor && (
                    <Label
                      label={"Expected"}
                      value={expectedCount}
                      valueStyle={{ fontWeight: "bold" }}
                    />
                  )}
                </Grid>
                <Grid item xs={2}>
                  {!isVendor && (
                    <Label
                      label={"Selected"}
                      value={orderedCount}
                      valueStyle={{ fontWeight: "bold" }}
                    />
                  )}
                </Grid>
                <Grid item xs={2}>
                  {!isVendor && (
                    <Label
                      label={"As per Vendor"}
                      value={
                        expectedCount > orderedCount
                          ? expectedCount - orderedCount
                          : 0
                      }
                      valueStyle={{ fontWeight: "bold" }}
                    />
                  )}
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3} style={{ padding: "24px" }}>
                {(categoryGroupItem.subCategories || []).map(
                  (subCatGroup, subCatGroupIndex) => {
                    const subCatWithProduct =
                      subCatGroup.items?.find((subItem) =>
                        defaultFilter(subItem)
                      ) || defaultFilter(null, subCatGroup);

                    const selectedCount =
                        (subCatGroup?.items || []).reduce(
                          (totalQuantity, item) =>
                            totalQuantity + (item?.quantity || 0),
                          0
                        ) || 0,
                      asPerVendorChoiceCount =
                        subCatGroup?.quantity > selectedCount
                          ? subCatGroup?.quantity - selectedCount
                          : 0;

                    return (
                      subCatWithProduct && (
                        <Grid
                          container
                          spacing={3}
                          item
                          justify={isVendor ? "flex-start" : "center"}
                          key={subCatGroupIndex}
                          className={classes.subCategoryHeader}
                          style={{
                            backgroundColor:
                              subCatGroupIndex % 2 === 0
                                ? "#3ebfc2"
                                : "#dbab27",
                          }}
                        >
                          <Grid item xs={3}>
                            <Label
                              label={"Sub Category"}
                              value={subCatGroup?.subCategory?.name || "-"}
                              labelStyle={{ color: "white" }}
                              valueStyle={{
                                color: "white",
                                fontWeight: "bold",
                              }}
                            />
                          </Grid>

                          <Grid item xs={2}>
                            {!isVendor && (
                              <Label
                                labelStyle={{ color: "white" }}
                                valueStyle={{
                                  color: "white",
                                  fontWeight: "bold",
                                }}
                                label={"Expected"}
                                value={subCatGroup?.quantity || 0}
                              />
                            )}
                          </Grid>

                          <Grid item xs={2}>
                            {!isVendor && (
                              <Label
                                labelStyle={{ color: "white" }}
                                valueStyle={{
                                  color: "white",
                                  fontWeight: "bold",
                                }}
                                label={"Selected"}
                                value={selectedCount}
                              />
                            )}
                          </Grid>

                          <Grid item xs={2}>
                            {!isVendor && (
                              <Label
                                labelStyle={{
                                  color: "white",
                                  fontWeight: "bold",
                                }}
                                valueStyle={{
                                  color: "white",
                                  fontWeight: "bold",
                                }}
                                label={"As per Vendor"}
                                value={asPerVendorChoiceCount}
                              />
                            )}
                          </Grid>

                          {asPerVendorChoiceCount > 0 &&
                          defaultFilter(null, subCatGroup) ? (
                            <AssignAsPerVendor
                              subCatGroup={subCatGroup}
                              subCatGroupIndex={subCatGroupIndex}
                              categoryGroupItemIndex={categoryGroupItemIndex}
                              isEditable={isEditable}
                              vendorCodes={vendorCodes}
                              categoryGroupItem={categoryGroupItem}
                              asPerVendorChoiceCount={asPerVendorChoiceCount}
                            />
                          ) : null}

                          <SubCategoryGroup
                            subCatGroup={subCatGroup}
                            classes={classes}
                            categoryGroupItemIndex={categoryGroupItemIndex}
                            subCatGroupIndex={subCatGroupIndex}
                            vendorCodes={vendorCodes}
                            isEditable={isEditable}
                          />
                        </Grid>
                      )
                    );
                  }
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

const AssignAsPerVendor = ({
  isEditable,
  vendorCodes = [],
  subCatGroupIndex,
  categoryGroupItemIndex,
  categoryGroupItem,
  asPerVendorChoiceCount,
  subCatGroup,
}) => {
  const { control, watch } = useFormContext();
  const values = watch();

  const { fields, append, remove } = useFieldArray({
    control,
    name: `categories[${categoryGroupItemIndex}].subCategories[${subCatGroupIndex}].vendors`,
  });

  return (
    <Grid container spacing={3} style={{ marginBottom: "24px" }}>
      <Grid item xs={12}>
        <Paper>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    colspan={isEditable && isAdmin ? 6 : 5}
                    style={{ backgroundColor: "black", color: "white" }}
                  >
                    <center>
                      <b>
                        As per{" "}
                        {`${
                          values.asPer?.vendorCode
                            ? `Vendor: ${values.asPer?.vendorCode}`
                            : values.asPer?.collectionLine?.name
                            ? `Collection Line: ${values.asPer?.collectionLine?.name}`
                            : null
                        }`}
                      </b>
                    </center>
                  </TableCell>
                  <TableCell
                    style={{ backgroundColor: "black", color: "white" }}
                  >
                    {isEditable && isAdmin && (
                      <MyHoverIcons
                        muiIcon={<AddCircleOutlineIcon />}
                        muiIconHover={<AddCircleIcon />}
                        tooltip={"Add Vendor"}
                        onClick={() => {
                          const obj = {
                            orderStatus:
                              enums["vendor-portal"].orderStatus.pending,
                          };

                          // if (
                          //   fields.length === 0 && // only automate first row
                          //   values?.asPer?.vendorCode // moved to backend
                          // ) {
                          //   obj.vendorCode = values?.asPer?.vendorCode;

                          //   obj.quantity = asPerVendorChoiceCount;
                          // } else if (fields.length === 0) {
                          //   obj.quantity = asPerVendorChoiceCount;
                          // }

                          obj.dueDate = values.dateExpected;

                          append(obj);
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>

                {fields.length ? (
                  <TableRow>
                    <TableCell>Vendor</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>{`Avg Gross Wt (g)`}</TableCell>
                    <TableCell>{`Approx Weight (g)`}</TableCell>
                    <TableCell>Due Date</TableCell>
                    <TableCell>Order Status</TableCell>
                    {isEditable && isAdmin && <TableCell>Action</TableCell>}
                  </TableRow>
                ) : null}
              </TableHead>
              <TableBody>
                {fields.map((vendor, vendorIndex) =>
                  defaultFilter(null, subCatGroup) ? (
                    <TableRow key={vendor.id}>
                      <TableCell>
                        {isEditable && isAdmin ? (
                          <RHAutoComplete
                            style={{ width: "120px" }}
                            options={vendorCodes}
                            disableClearable
                            name={`categories[${categoryGroupItemIndex}].subCategories[${subCatGroupIndex}].vendors[${vendorIndex}].vendorCode`}
                          />
                        ) : (
                          <b>{vendor.vendorCode}</b>
                        )}
                      </TableCell>
                      <TableCell>
                        {isEditable && isAdmin ? (
                          <RHTextField
                            style={{ width: "120px" }}
                            type={enums.dataTypes.number}
                            name={`categories[${categoryGroupItemIndex}].subCategories[${subCatGroupIndex}].vendors[${vendorIndex}].quantity`}
                          />
                        ) : (
                          vendor.quantity
                        )}
                      </TableCell>
                      <TableCell>
                        {roundOff(subCatGroup.averageGrossWeight, {
                          weight: true,
                        })}
                      </TableCell>
                      <TableCell>
                        {(
                          subCatGroup.averageGrossWeight *
                          values.categories[categoryGroupItemIndex]
                            .subCategories[subCatGroupIndex].vendors[
                            vendorIndex
                          ]?.quantity
                        ).toFixed(3)}
                      </TableCell>
                      <TableCell>
                        {isEditable && isAdmin ? (
                          <RHDatePicker
                            style={{ width: "200px" }}
                            disablePast
                            name={`categories[${categoryGroupItemIndex}].subCategories[${subCatGroupIndex}].vendors[${vendorIndex}].dueDate`}
                          />
                        ) : vendor.dueDate ? (
                          formatDateToRead(vendor.dueDate)
                        ) : null}
                      </TableCell>
                      <TableCell>
                        {isEditable ? (
                          <RHAutoComplete
                            style={{ width: "240px", maxWidth: "240px" }}
                            options={Object.values(
                              enums["vendor-portal"].orderStatus
                            ).filter((status) =>
                              isVendor
                                ? !(
                                    status ===
                                      enums["vendor-portal"].orderStatus
                                        .assigned ||
                                    status ===
                                      enums["vendor-portal"].orderStatus.pending
                                  )
                                : true
                            )}
                            name={`categories[${categoryGroupItemIndex}].subCategories[${subCatGroupIndex}].vendors[${vendorIndex}].orderStatus`}
                          />
                        ) : (
                          vendor.orderStatus
                        )}
                      </TableCell>
                      {isEditable && isAdmin && (
                        <TableCell>
                          <MyHoverIcons
                            muiIcon={<RemoveCircleOutlineOutlinedIcon />}
                            muiIconHover={<RemoveCircleIcon />}
                            onClick={() => remove(vendorIndex)}
                            tooltip={"Remove"}
                          />
                        </TableCell>
                      )}
                    </TableRow>
                  ) : null
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
};

const SubCategoryGroup = ({
  subCatGroup,
  classes,
  categoryGroupItemIndex,
  subCatGroupIndex,
  vendorCodes,
  isEditable,
}) => {
  // Closure..
  const ProductRow = ({ item, itemIndex, product, stockImageLocation }) => {
    const [openImagePreview, setOpenImagePreview] = useState([]);

    return (
      <TableRow>
        {openImagePreview.length ? (
          <MyCarousel
            items={openImagePreview}
            openPreview={true}
            setOpenPreview={() => setOpenImagePreview([])}
          />
        ) : null}

        <TableCell
          align="center"
          onClick={() =>
            setOpenImagePreview([
              {
                fileName: "Image.png",
                fileType: "image/png",
                location: stockImageLocation,
                signedUrl: stockImageLocation,
              },
            ])
          }
        >
          <LazyLoad>
            <img
              src={stockImageLocation}
              alt={`Product ${itemIndex + 1}`}
              className={classes.productImage}
              style={{ objectFit: "contain" }}
              onError={(event) => {
                event.target.src = imageExpired;
              }}
            />
          </LazyLoad>
        </TableCell>

        {/* Data */}
        <TableCell>{product.styleNo}</TableCell>
        <TableCell>{product.collectionLine?.name || "-"}</TableCell>
        <TableCell>
          {`${roundOff(product?.grossWeight, { weight: true })}, ${roundOff(
            product?.netWeight,
            { weight: true }
          )}`}
        </TableCell>

        <TableCell>
          {`${product.purity?.value ? product.purity?.value + " KT" : "-"}, ${
            product.zone || "-"
          }`}
        </TableCell>

        <TableCell>
          <div>{item?.quantity}, </div>
          <div>{item?.remarks || "-"}</div>
        </TableCell>
        <TableCell>
          {isEditable && (isAdmin || isSuperAdmin) ? (
            <RHAutoComplete
              options={vendorCodes}
              disableClearable
              textFieldProps={{ style: { maxWidth: "150px", width: "150px" } }}
              name={`categories[${categoryGroupItemIndex}].subCategories[${subCatGroupIndex}].items[${itemIndex}].product.vendorCode`}
            />
          ) : (
            <b>{product.vendorCode || "-"}</b>
          )}
        </TableCell>
        <TableCell>
          {isEditable && (isAdmin || isSuperAdmin) ? (
            <RHDatePicker
              name={`categories[${categoryGroupItemIndex}].subCategories[${subCatGroupIndex}].items[${itemIndex}].dueDate`}
              disablePast
              style={{ maxWidth: "200px", width: "200px" }}
            />
          ) : item.dueDate ? (
            formatDateToRead(item.dueDate)
          ) : (
            "-"
          )}
        </TableCell>
        <TableCell>
          {isEditable ? (
            <RHAutoComplete
              options={Object.values(enums["vendor-portal"].orderStatus).filter(
                (status) =>
                  isVendor
                    ? !(
                        status ===
                          enums["vendor-portal"].orderStatus.assigned ||
                        status === enums["vendor-portal"].orderStatus.pending
                      )
                    : true
              )}
              textFieldProps={{ style: { maxWidth: "200px", width: "200px" } }}
              name={`categories[${categoryGroupItemIndex}].subCategories[${subCatGroupIndex}].items[${itemIndex}].orderStatus`}
            />
          ) : (
            item.orderStatus || "-"
          )}
        </TableCell>
      </TableRow>
    );
  };

  return (subCatGroup.items || []).filter((item) => defaultFilter(item))
    ?.length ? (
    <TableContainer component={Paper}>
      <Table aria-label="products table">
        <TableHead>
          <TableCell style={{ fontWeight: "bold" }}></TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Design</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Collection Line</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Gross, Net</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Purity, Zone</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Qty, Remarks</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>#Vendor</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Due Date</TableCell>
          <TableCell style={{ fontWeight: "bold" }}>Status</TableCell>
        </TableHead>
        <TableBody>
          {(subCatGroup.items || []).map((item, itemIndex) => {
            const product = item?.product;

            const stockImageLocation = getProductImageUri({
              ...(item?.product || {}),
              imageFlag: true,
            });

            return (
              defaultFilter(item) && (
                <ProductRow
                  item={item}
                  itemIndex={itemIndex}
                  product={product}
                  stockImageLocation={stockImageLocation}
                />
              )
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  ) : null;
};

export default CategoryGroup;

// #star
{
  /* <Grid item xs={3} container justify="flex-end">
                  <div
                    style={{
                      display: "flex",
                      // alignItems: "center",
                      // paddingTop: "34px",
                    }}
                  >
                    <div
                      style={{
                        position: "relative",
                        display: "inline-flex",
                        // alignItems: "center",
                        // padding: "8px 12px",
                        borderRadius: "12px",
                        backgroundColor: sameDivision ? "black" : "grey",
                        color: sameDivision ? "gold" : "white",
                        fontSize: "14px",
                        fontWeight: "bold",
                        overflow: "hidden",
                      }}
                    >
                      {sameDivision ? (
                        <div style={{ animation: "shine 1.5s infinite" }}>
                          ⭐ {categoryGroupItem?.division?.name || ""}
                        </div>
                      ) : (
                        <div>{categoryGroupItem?.division?.name || ""}</div>
                      )}
                    </div>
                  </div>
                </Grid> */
}
