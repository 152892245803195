import React, { useEffect, useRef, useState } from "react";
import enums from "helpers/enums";
import {
  formatDateToRead,
  isDivisionControlled,
  isVendor,
  selectedDivisionId,
  uniqueArray,
  userDetails,
} from "@ui/Utils/helper";
import Header from "@ui/ComponentUtils/Header";
import { searchWithHook } from "../../api";
import StockOrders from "./StockOrders";
import { Button, Grid } from "@material-ui/core/index";

const columns = [
  //COLUMNS ARE NOT READ FROM HERE DUDE... GO TO StockOrders.js to see this displayed code
  {
    title: "Recvd.",
    // field: "orderId",
    // render: (row) => row?.orderId,
  },
  {
    title: "Order ID",
    field: "orderId",
    render: (row) => row?.orderId,
  },
  {
    title: "Destination Zone\nSource Zone",
  },
  {
    title: "Expected\nSelected\nAs per Vendor",
    hidden: isVendor,
  },
  // {
  //   title: "Expected",
  //   sorting: false,
  //   // render: (row) =>
  //   //   (row?.stockOrder || []).reduce((acc, catGroup) => {
  //   //     const groupTotal = (catGroup.subCategories || []).reduce(
  //   //       (groupAcc, subCatGroup) => {
  //   //         return groupAcc + (subCatGroup?.quantity || 0);
  //   //       },
  //   //       0
  //   //     );
  //   //     return acc + groupTotal;
  //   //   }, 0),
  // },
  // {
  //   title: "Selected",
  //   sorting: false,
  //   // render: (row) =>
  //   //   (row?.stockOrder || []).reduce((acc, catGroup) => {
  //   //     const groupTotal = (catGroup?.subCategories || []).reduce(
  //   //       (groupAcc, subCategory) => {
  //   //         const subCategoryQuantity = (subCategory?.items || []).reduce(
  //   //           (itemAcc, item) => itemAcc + (item?.quantity || 0),
  //   //           0
  //   //         );
  //   //         return groupAcc + subCategoryQuantity;
  //   //       },
  //   //       0
  //   //     );
  //   //     return acc + groupTotal;
  //   //   }, 0),
  // },
  {
    title: "Vendors Included",
    hidden: isVendor,
  },
  {
    title: "Overall Remarks",
    field: "overallRemarks",
  },
  {
    title: "Order Status",
    sorting: false,
  },
  {
    title: "Expected By",
  },
  {
    title: "Created",
    // render: (row) => row?.createdBy?.firstName + " " + row?.createdBy?.lastName,
  },
];

const StockOrdersList = () => {
  const model = enums.models["e-creatives"]?.stockOrders,
    searchFields = [
      "orderId",
      "phone",
      "email",
      "items.product.sku",
      "items.product.styleNo",
    ],
    userFields = ["$createdBy.firstName", "$createdBy.lastName"],
    headerTitle = "Stock Orders",
    title = "",
    hideAction = { create: true, delete: true, selection: true },
    onRowClick = true;

  const [constData, setConstData] = useState([]);
  const [data, setData] = useState([]);
  const [tab, setTab] = useState("In Progress");
  const [totalCalc, setTotalCalc] = useState({
    grossWeight: 0,
    netWeight: 0,
  });
  const [currentEditRow, setCurrentEditRow] = React.useState("none");

  useEffect(() => {
    getData();
  }, [tab]);

  const getData = async () => {
    let tabCondition = {
      received: tab === "In Progress" ? { $ne: true } : true,
    };

    if (isVendor) {
      let tabWiseOrderStatus = [];
      if (tab === "In Progress") {
        tabWiseOrderStatus = Object.values(
          enums["vendor-portal"].orderStatus
        ).filter(
          (status) =>
            !(
              status === enums["vendor-portal"].orderStatus.pending ||
              status === enums["vendor-portal"].orderStatus.delivered
            )
        );
      } else {
        delete tabCondition.received;

        tabWiseOrderStatus = Object.values(
          enums["vendor-portal"].orderStatus
        ).filter(
          (status) => status === enums["vendor-portal"].orderStatus.delivered
        );
      }

      tabCondition["$and"] = [
        {
          // Only records with current login vendor's vendorCode
          $or: [
            {
              ["categories.subCategories.items.product.vendorCode"]: {
                $in: userDetails.vendorCode,
              },
            },
            {
              ["categories.subCategories.vendors.vendorCode"]: {
                $in: userDetails.vendorCode,
              },
            },
          ],
        },

        {
          // Only Accepted and above Order Status
          $or: [
            {
              ["categories.subCategories.items.orderStatus"]: {
                $in: tabWiseOrderStatus,
              },
            },
            {
              ["categories.subCategories.vendors.orderStatus"]: {
                $in: tabWiseOrderStatus,
              },
            },
          ],
        },
      ];
    }

    try {
      let response = await searchWithHook(model, {
        ...tabCondition,
        sort: { _id: -1 },
      });

      setTotalCalc({
        ...(response.metaInfo || {}),
      });

      response = response?.records || [];
      setConstData(response);
      setData(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Header links={[]} pageTitle={headerTitle} actions={[]} />

      {currentEditRow === "none" && (
        <Grid container spacing={2}>
          <Grid item>
            <Button
              variant="contained"
              component="label"
              style={{
                backgroundColor: tab === "Completed" ? "#7CD093" : "black",
                color: "white",
              }}
              onClick={() => setTab("Completed")}
            >
              Completed Orders
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              component="label"
              style={{
                backgroundColor: tab === "In Progress" ? "#7CD093" : "black",
                color: "white",
              }}
              onClick={() => setTab("In Progress")}
            >
              In Progress
            </Button>
          </Grid>
        </Grid>
      )}

      <StockOrders
        constData={constData}
        data={data}
        setData={setData}
        columns={columns}
        title={`Gross: ${totalCalc.totalGrossWeight || "0"} << -- >> ${
          totalCalc.totalNetWeight || "0"
        } :Net`}
        getData={getData}
        setTotalCalc={setTotalCalc}
        totalCalc={totalCalc}
        currentEditRow={setCurrentEditRow}
        setCurrentEditRow={setCurrentEditRow}
      />
    </div>
  );
};

export default StockOrdersList;
