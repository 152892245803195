import React, { useEffect, useState } from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import { ReactComponent as AccountBox } from "../../assets/landingpage/account.svg";
import { useAuth } from "@ui/components/AuthProvider";
import { RectangleDiv, TopBarData, BottomData } from "./landing-page.style";
import { isAdmin, isVendor, userDetails } from "@ui/Utils/helper";
import commonConfig from "config/commonConfig";
import { uniqueId } from "lodash";
import { Navigate, useNavigate } from "react-router-dom";
import DataDynamic from "../../assets/landingpage/Animations/Data/data-center-engineers.gif";
import OrderDynamic from "../../assets/landingpage/Animations/Orders/shopping-options.gif";
import VendorDynamic from "../../assets/landingpage/Animations/vendorPortal/online-inventory.gif";
import ChitthiDynamic from "../../assets/landingpage/Animations/Chitthi/money-inflation-finance.gif";
import DataIcon from "../../assets/landingpage/bar-content/data.svg";
import OrderStatic from "../../assets/landingpage/bar-content/order.svg";
import VendorStatic from "../../assets/landingpage/bar-content/vendor-portal.svg";
import ChitthiStatic from "../../assets/landingpage/bar-content/rojmel.svg";
import LogoutIcon from "@mui/icons-material/Logout";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { StackStyled } from "./Account.style";
import { logout } from "@ui/Auth/api";
import MyHoverIcons from "@ui/assets/commonIcons/customIcons/MyHoverIcons";

export const NAVIGATE_APP_TO = "navigateAppTo";

function LandingPage({ homePath }) {
  const auth = useAuth();
  const currentDomain = window.location.href?.toString().split("/");
  const [isHoveredIndex, setIsHoveredIndex] = useState(null);

  useEffect(() => {
    let navigateTo = window.localStorage.getItem(NAVIGATE_APP_TO);
    navigateTo = navigateTo ? JSON.parse(navigateTo) : null;

    if (navigateTo) {
      handleNavigation(navigateTo);
    }
  }, []);

  const handleMouseEnter = (index) => {
    setIsHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setIsHoveredIndex(null);
  };

  const showHomePage =
    currentDomain?.length <= 4 ||
    currentDomain?.find((param) => param === "home");

  const navigate = useNavigate();

  const handleNavigation = (appModule) => {
    window.localStorage.setItem(NAVIGATE_APP_TO, JSON.stringify(appModule));

    let url = `${commonConfig.hyperLinks[appModule.key]}#/`;
    const windowLocation = window.location.host?.toString().toLowerCase();

    switch (appModule.key) {
      case "data-engine":
        if (url.match("localhost")) url = "http://localhost:4000/#/";

        url += "data-engine/inventory";
        break;
      case "e-creatives":
        const path = isAdmin ? "orders" : "dashboard";
        if (windowLocation.match("orderpro")) {
          url = `https://${window.location.host}/#/e-creatives/${path}`;
        } else url += "e-creatives/" + path;

        break;
      case "cortex":
        // url += "cortex/" + (isAdmin ? "training" : "training");
        break;

      case "vendor-portal":
        const vPath = isAdmin ? "dashboard" : "dashboard";
        url += "vendor-portal/" + vPath;
        break;

      case "chitthi":
        url += "chitthi/" + (isAdmin ? "invoices" : "dashboard");
        break;
    }

    if (url?.match(commonConfig.appModule)) {
      window.location.href = url;
      window.location.reload();
    } else {
      window.open(url);
    }
  };

  const getModuleData = (key) => {
    let name = key;

    switch (key) {
      case "cortex":
        name = "Cortex";
        break;
      case "data-engine":
        name = "Data Engine";
        break;
      case "e-creatives":
        name = "Order Pro";
        break;

      case "vendor-portal":
        name = "Vendor Portal";
        break;

      case "chitthi":
        name = "Chitthi";
        break;
    }

    return {
      name,
      home: commonConfig.hyperLinks?.[key],
    };
  };

  const getIconWithName = (key) => {
    let Icons = {
      static: DataIcon,
      dynamic: DataDynamic,
    };

    switch (key) {
      case "data-engine":
        Icons = {
          static: DataIcon,
          dynamic: DataDynamic,
        };
        break;

      case "e-creatives":
        Icons = {
          static: OrderStatic,
          dynamic: OrderDynamic,
        };
        break;

      case "vendor-portal":
        Icons = {
          static: VendorStatic,
          dynamic: VendorDynamic,
        };
        break;

      case "chitthi":
      default:
        Icons = {
          static: ChitthiStatic,
          dynamic: ChitthiDynamic,
        };
        break;
    }

    return Icons;
  };

  const getColorWithName = (key) => {
    let color = "#FFEF99";

    switch (key) {
      case "cortex":
        color = "#EACCFF";
        break;
      case "data-engine":
        color = "#BFE0FF";
        break;
      case "e-creatives":
        color = "#C8EEC8";
        break;

      case "vendor-portal":
        color = "#FDDE9A";
        break;

      case "chitthi":
      default:
        color = "#FFEF99";
        break;
    }

    return color;
  };

  const barData = auth?.user?.client?.appModules
    ?.map((module) => ({
      ...module,
      name: getModuleData(module?.key).name,
      icon: getIconWithName(module?.key),
      backgroundColor: getColorWithName(module?.key),
    }))
    .filter((appModule) => {
      if (isVendor) {
        return appModule.key === "vendor-portal";
      }

      if (userDetails._id) return true;

      return false;
    })
    .filter((data) =>
      Object.keys(commonConfig.hyperLinks || {}).find(
        (module) => module === data?.key
      )
    );

  if (!auth?.user) {
    return <Navigate to="/signIn" />;
  }
  if (showHomePage && commonConfig.appModule !== "iam") {
    return (
      <Grid container spacing={4}>
        <Grid container item xs={12} justify="flex-end">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography
              variant="h5"
              style={{
                marginLeft: "24px",
                fontFamily: "monospace",
                fontWeight: "bold",
                paddingTop: "24px",
              }}
            >
              {userDetails.fullName}
            </Typography>
            <StackStyled flexDirection="row" alignItems="center" gap="1rem">
              <AccountBox style={{ marginRight: "48px" }} />
              <MyHoverIcons
                tooltip={"Logout"}
                onClick={() => logout()}
                muiIcon={<LogoutOutlinedIcon />}
                muiIconHover={
                  <LogoutIcon style={{ transform: "scale(1.5)" }} />
                }
              />
            </StackStyled>
          </Box>
        </Grid>

        {barData?.map((data, index) => (
          <Grid item xs={barData.length > 4 ? 2 : 3} key={uniqueId()}>
            <RectangleDiv
              backgroundColor={data?.backgroundColor}
              onClick={() => handleNavigation(data)}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
            >
              <TopBarData>
                <img
                  className="top_bar_content static"
                  src={
                    isHoveredIndex === index
                      ? data?.icon?.dynamic
                      : data?.icon?.static
                  }
                  alt="top-bar-icon"
                  data-testid="top-bar-content"
                  width={100}
                />
              </TopBarData>
              <BottomData data-testid="bottom-bar-content">
                {data?.name}
              </BottomData>
            </RectangleDiv>
          </Grid>
        ))}
      </Grid>
    );
  }
  return <Navigate to={homePath} />;
}

export default LandingPage;
