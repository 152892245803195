import React, { Fragment, useEffect, useState } from "react";
import Header from "@ui/ComponentUtils/Header";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useFormContext } from "react-hook-form";
import enums from "helpers/enums";
import MainSection from "@ui/MuiComponents/Section/MainSection";
import Label from "@ui/components/Label";
import { Grid } from "@material-ui/core/index";
import {
  formatDateToRead,
  isVendor,
  oopsNotFound,
  selectedDivisionName,
  uniqueArray,
} from "@ui/Utils/helper";

import { defaultFilter } from "../Detail";
import { getSingleRecord, search } from "@ui/ComponentUtils/blueprintAPIs";
import "./divisionAnimation.css";
import CategoryGroup from "./CategoryGroup";

// const columns = [
//   { label: "Product", field: "product.styleNo" },
//   { label: "Category", field: "product.category.name" },
//   { label: "Image", field: "product.image" },
//   { label: "Melting", field: "melting" },
//   { label: "Screw", field: "screw" },
//   { label: "Size", field: "size" },
//   { label: "Color", field: "color" },
//   { label: "Remarks", field: "remarks" },
//   { label: "Quantity", field: "quantity" },
// ];

const DetailHeader = ({ loading, setLoading, initialValues, ...props }) => {
  const { isEditable, onSubmit, setIsEditable } = props;
  const formMethods = useFormContext();
  const { watch, setValue } = formMethods;
  const values = watch();
  const customerId = values.createdBy?._id;
  const model = enums.models["e-creatives"]?.stockOrders;
  const params = useParams();
  const navigate = useNavigate();
  const [vendorCodes, setVendorCodes] = useState([]);
  const [setting, setSetting] = useState({});
  const [vendorsIncluded, setVendorsIncluded] = React.useState([]);

  React.useEffect(() => {
    const tempVendorsIncluded = [];
    if (values.asPer && values.asPer.vendorCode) {
      tempVendorsIncluded.push(values.asPer.vendorCode);
    }

    (values.categories || []).forEach((order) => {
      // Extract vendor code from asPer

      // Extract vendor codes from subCategories.vendors
      order.subCategories.forEach((subCategory) => {
        subCategory.vendors.forEach((vendor) => {
          if (vendor.vendorCode) {
            tempVendorsIncluded.push(vendor.vendorCode);
          }
        });
      });

      // Extract vendor codes from subCategories.items.product
      order.subCategories.forEach((subCategory) => {
        subCategory.items.forEach((item) => {
          if (item.product && item.product.vendorCode) {
            tempVendorsIncluded.push(item.product.vendorCode);
          }
        });
      });
    });

    setVendorsIncluded(uniqueArray(tempVendorsIncluded));
  }, [values.categories]);

  useEffect(() => {
    (async () => {
      try {
        const res =
          (await search(enums.models["vendor-portal"]?.vendorCodes)) || [];
        setVendorCodes(res?.[0]?.availableCodes || []);
      } catch (err) {
        console.log(err);
      }
    })();

    (async () => {
      try {
        setSetting(
          (await getSingleRecord(enums.models["vendor-portal"]?.settings)) || {}
        );
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  const handleCancel = () => {
    if (window.confirm("Are you sure to cancel? Changes will be discarded.")) {
      if (params?.code === "create") {
        navigate("/" + enums.models["e-creatives"].stockOrders);
      } else {
        formMethods.reset();
        setIsEditable(false);
      }
    }
  };

  const links = [
    {
      name: enums.ordersType.stockOrder,
      url: "#/" + enums.models["e-creatives"].stockOrders,
      isDisplayLink: true,
    },
    {
      name: `${values.orderId || ""}`,
      url: "",
      isDisplayLink: false,
    },
  ];

  const actions = [
    // {
    //   label: "Download PDF",
    //   tooltip: setting.pdfTemplate
    //     ? "Download PDF"
    //     : "Select PDF Template in Settings",
    //   iconName: "pdf",
    //   hidden: isEditable,
    //   disabled: setting.pdfTemplate ? false : true,
    //   onClick: downloadPdf,
    // },
    {
      label: "Cancel",
      iconName: "cancel",
      hidden: !isEditable,
      onClick: handleCancel,
    },
    {
      label: "Save",
      iconName: "save",
      hidden: !isEditable,
      type: "submit",
      onClick: formMethods.handleSubmit(onSubmit),
    },
    {
      label: "Edit",
      iconName: "edit",
      hidden: isEditable,
      onClick: () => {
        setIsEditable(true);
      },
    },
  ];

  if (loading) return "";

  const headerValueStyle = {
    fontWeight: "bold",
  };

  return (
    <>
      <Header links={links} pageTitle={"Stock Order"} actions={actions} />

      <Grid container spacing={3} style={{ marginBottom: "24px" }}>
        <Grid item xs={4}>
          <Label
            label={"Order ID"}
            value={values.orderId}
            valueStyle={headerValueStyle}
          />
        </Grid>

        <Grid item xs={4}>
          <Label
            label={"Destination Zone"}
            value={values.destinationZone}
            valueStyle={headerValueStyle}
          />
        </Grid>

        <Grid item xs={4}>
          <Label
            label={"Source Zone"}
            value={values.sourceZone}
            valueStyle={headerValueStyle}
          />
        </Grid>

        <Grid item xs={4}>
          <Label
            label={"Overall Remarks"}
            value={values.overallRemarks}
            valueStyle={headerValueStyle}
          />
        </Grid>

        {!isVendor && (
          <Grid item xs={4}>
            <Label
              label={"Vendors Included"}
              value={vendorsIncluded.join(", ")}
              valueStyle={headerValueStyle}
            />
          </Grid>
        )}

        <Grid item xs={4}>
          <Label
            label={"Expected By"}
            value={
              values.dateExpected ? formatDateToRead(values.dateExpected) : null
            }
            valueStyle={headerValueStyle}
          />
        </Grid>

        <Grid item xs={4}>
          <Label
            label={"Created"}
            value={`${
              values.createdBy?.firstName + " " + values.createdBy?.lastName
            }\n${formatDateToRead(values.dateCreated)}\n${
              values.createdBy?.phone
            }`}
            valueStyle={headerValueStyle}
          />
        </Grid>
      </Grid>

      {values.categories?.length ? (
        (values.categories || []).map((item, itemIndex) => {
          const subCatWithProduct = item?.subCategories?.find(
            (subCat) =>
              subCat.items?.find((subItem) => defaultFilter(subItem)) ||
              defaultFilter(null, subCat)
          );
          return (
            subCatWithProduct && (
              <ProductItem
                key={itemIndex}
                item={item}
                itemIndex={itemIndex}
                isEditable={isEditable}
                customerId={customerId}
                vendorCodes={vendorCodes}
                initialValues={initialValues}
              />
            )
          );
        })
      ) : (
        <div style={{ textAlign: "center" }}>
          <div>
            <img
              src={oopsNotFound}
              style={{ height: "320px", width: "440px" }}
              alt="Not Found"
            />
          </div>
          <div>
            <h3>{`No products were ordered in ${selectedDivisionName} Division`}</h3>
          </div>
        </div>
      )}
    </>
  );
};

// const diamondColumns = [
//   { label: "Sr No", field: "srNo" },
//   { label: "Quantity", field: "diamondQuantity" },
//   { label: "Total Carat (cts)", field: "totalCarat" },

//   { label: "Shape", field: "shape" },
//   { label: "Color", field: "color" },

//   { label: "Solitaire Length (mm)", field: "solitaireSize.length" },
//   { label: "Solitaire Width (mm)", field: "solitaireSize.width" },

//   { label: "Sieve Length (mm)", field: "sieveSize.length" },
//   { label: "Sieve Width (mm)", field: "sieveSize.width" },

//   { label: "Cut", field: "cut" },
//   { label: "Clarity", field: "clarity" },

//   { label: "Polish", field: "polish" },
//   { label: "Symmetry", field: "symmetry" },
//   { label: "Fluorescence Intensity", field: "fluorescenceIntensity" },
//   { label: "Grading Lab", field: "gradingLab" },
// ];

export const ProductItem = ({
  item,
  itemIndex,
  isEditable,
  vendorCodes = [],
  // customerId,
}) => {
  return (
    <MainSection
      title={""}
      titleStyle={{ color: "black" }}
      style={{
        marginBottom: "24px",
        // opacity: sameDivision ? 1 : 0.5 :( Stock Order is only division level
      }}
    >
      <CategoryGroup
        categoryGroupItem={item}
        categoryGroupItemIndex={itemIndex}
        isEditable={isEditable}
        vendorCodes={vendorCodes}
      />
    </MainSection>
  );
};

const componentStyles = {
  greenLabel: { color: "black" },
};

export default DetailHeader;
